<template>
  <div>

    <div class="mb-5">
      <div v-if="element.config.url"
           class="d-flex justify-space-between align-center pa-5 wsRoundedLight"
           :style="`border : 1px solid ${wsACCENT}`"
      >
        <div class="d-flex align-center">
          <v-icon class="mr-3" :color="wsACCENT">mdi-paperclip</v-icon>
          <h5 class="shorten-text" style="word-break: break-word" :style="`color : ${wsACCENT}`">{{ element.content }}</h5>
        </div>

        <v-btn :color="wsACCENT" @click="deleteFile" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>

      </div>
      <ws-file-uploader
          v-else
          @success="addFile"
          public
      />
    </div>

    <ws-text-field
        v-model="$store.state.finemailer.selectedElement.content"
        @change="$store.state.finemailer.elementEditTrigger++"
        :label="$t('Name')"
        :placeholder="$t('EnterName')"
        clearable
        avalon-style
    />


  </div>
</template>

<script>
import finemailer from "@/modules/finemailer/mixins/finemailer";
export default {
  name: "emailSettingsGeneral",
  mixins : [finemailer],
  components : {
  },
  computed : {
    element() {
      return this.$store.state.finemailer.selectedElement
    }
  },
  methods : {
    deleteFile() {
      let element  = this.element
      element.content = null
      element.config.name = event.name
      this.$store.state.finemailer.selectedElement = this.COPY(element)
      this.$store.state.finemailer.elementEditTrigger++
    },

    addFile(event) {
      let element  = this.element
      element.config.url = event.url
      element.content = event.name
      this.$store.state.finemailer.selectedElement = this.COPY(element)
      this.$store.state.finemailer.elementEditTrigger++

    }
  }
}
</script>

<style scoped>

</style>