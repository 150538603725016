export default {
    props : {
        config : {
            type : Object,
            default() { return {} }
        }
    },
    data() {
        return {
            CONFIG : {},
            defaultConfig : {},
            EMAIL_BLOCK_EDIT_ACTIONS_SELECT : [
                { icon : 'mdi-arrow-up' , value : 'move_up' },
                { icon : 'mdi-arrow-down' , value : 'move_down' },
                { icon : 'mdi-content-copy' , value : 'duplicate' , size : 20},
                { icon : 'mdi-delete-outline' , value : 'delete' },
            ],
            ALIGNMENT_SELECT : [
                { icon : 'mdi-format-align-left'   , value : 'left' },
                { icon : 'mdi-format-align-center' , value : 'center' },
                { icon : 'mdi-format-align-right'  , value : 'right' }
            ],
            PADDING_SIZES : [{ name : null , value : 0 } , { name : 's' , value : 8 } , { name :  'm' , value : 24 } , { name : 'l' , value : 50 } ],
            PADDING_SELECT : [
                { icon: 'mdi-cancel', value: null },
                { text: 'S', value: 's' },
                { text: 'M', value: 'm' },
                { text: 'L', value: 'l' },
                { icon: 'mdi-dots-horizontal', value: 'custom' },
            ],
            EMAIL_FONT_PAIRS_SELECT : [
                { heading : 'Helvetica' , paragraph : 'Helvetica' },
                { heading : 'Arial' , paragraph : 'Helvetica' },
                { heading : 'Times New Roman' , paragraph : 'Helvetica' },
                { heading : 'Georgia' , paragraph : 'Helvetica' },
                { heading : 'Verdana' , paragraph : 'Helvetica' },
                { heading : 'Courier' , paragraph : 'Courier' },
            ],
            EMAIL_FONTS_SELECT : [
                { text : 'Helvetica'       , value : 'Helvetica'       },
                { text : 'Arial'           , value : 'Arial'           },
                { text : 'Times New Roman' , value : 'Times New Roman' },
                { text : 'Georgia'         , value : 'Georgia'         },
                { text : 'Verdana'         , value : 'Verdana'         },
                { text : 'Courier'         , value : 'Courier'         },
            ],
        }
    },
    computed : {

        // Constructor
        EMAIL_MOBILE_VIEW() {
            return this.$store.state.finemailer.mobileView
        },
        EMAIL_FONT_WEIGHT_SELECT() {
            return  [
                { text : this.$t('mailer.editor.fonts.weight.regular') , value : 400 },
                { text : this.$t('mailer.editor.fonts.weight.bold')    , value : 600 },
            ]
        },
        EMAIL_FONT_CASE_SELECT() {
            return  [
                { text : this.$t('mailer.editor.fonts.weight.regular') , value : 'none' },
                { text : this.$t('mailer.editor.fonts.case.uppercase') , value : 'uppercase' },
                { text : this.$t('mailer.editor.fonts.case.lowercase')    , value : 'lowercase' },
            ]
        },
        EMAIL_WIDTH()  {
           return 700
        },
        HEADING_SIZES() {
            return {
                h1 : 28,
                h2 : 24,
                h3 : 20,
                h4 : 18,
                h5 : 16
            }
        },
        EMAIL_ELEMENTS_SELECT() {
            return [
                { text : this.$t('Text') ,
                    value : 'text' ,
                    icon : 'mdi-text',
                },
                { text : this.$t('Heading') ,
                    value : 'heading' ,
                    icon : 'mdi-format-title',
                },
                { text : this.$t('Image') ,
                    value : 'image' ,
                    icon : 'mdi-image',
                },
                { text : this.$t('Link') ,
                    value : 'link' ,
                    icon : 'mdi-link',
                },
                { text : this.$t('Button') ,
                    value : 'button' ,
                    icon : 'mdi-button-cursor',
                },
                { text : this.$t('Line') ,
                    value : 'line' ,
                    icon : 'mdi-format-line-weight',
                },
                { text : this.$t('File') ,
                    value : 'file' ,
                    icon : 'mdi-paperclip',
                },
            ]
        },

        EMAIL_COLOR_PALETTE_KEYS() {
            return Object.keys(this.$store.state.finemailer.defaultNewsletterConfig.palette)
        },
        EMAIL_CONFIG() {
            if (!this.$store.state.finemailer.constructorIsOn){
                return this.CONFIG
            }
            return this.$store.state.finemailer.newsletterConfig
        },

        // Newsletters
        RECEIVERS_GROUP_TYPES_SELECT() {
            return [
                { text : this.$t('mailer.wizard.settings.receivers.type.all')          , value : 'all' },
                { text : this.$t('mailer.wizard.settings.receivers.type.list'        ) , value : 'list' },
                // { text : this.$t('mailer.wizard.settings.receivers.type.leads'       ) , value : 'leads' },
                // { text : this.$t('mailer.wizard.settings.receivers.type.managers'    ) , value : 'managers' },
                // { text : this.$t('mailer.wizard.settings.receivers.type.users'       ) , value : 'users' },
                { text : this.$t('mailer.wizard.settings.receivers.type.course'      ) , value : 'course' },
            ]
        },
    },
    methods : {
        // NEWSLETTER
        NEWSLETTER_STATUS_COLOR(status) {
            const colors =  {
                draft : this.wsDARKLIGHT,
                error : this.wsWARNING,
                usererror : this.wsWARNING,
                review : this.wsATTENTION,
                done : this.wsSUCCESS,
                active : this.wsSUCCESS,
                planned : this.wsSUCCESS,
                declined : this.wsWARNING
            }
            return colors[status] || this.wsACCENT
        },

        // CONSTRUCTOR
        EMAIL_NEW_BLOCK_DEFAULT(addMarginTop = false) {
            let block = { config : { columns : 1}}
            if ( addMarginTop > 0 ) {
                block.config.margin_top = 16
            }
            return block
        },

        GET_ELEMENT_CURRENT_WIDTH(uuid) {
            const element = document.getElementById(`email_element_${uuid}`)
            const rect = element.getBoundingClientRect()
            return rect.width
        },
        GET_EMAIL_FONT_SIZE(tag) {

            let sizes = {
                h1 : this.EMAIL_CONFIG.font_size_h1 || 28,
                h2 : this.EMAIL_CONFIG.font_size_h2 ||24,
                h3 : this.EMAIL_CONFIG.font_size_h3 ||20,
                h4 : this.EMAIL_CONFIG.font_size_h4 ||18,
                h5 : this.EMAIL_CONFIG.font_size_h5 ||16,
                p  : this.EMAIL_CONFIG.font_size_p ||14,
                h6 : this.EMAIL_CONFIG.font_size_h6 ||12
            }

            return sizes[tag]
        },
        PREPARE_EMAIL_CONFIG() {
            Object.keys(this.$store.state.finemailer.defaultNewsletterConfig).forEach(group => {
                if ( !this.EMAIL_CONFIG[group]) {
                    this.$store.state.finemailer.newsletterConfig[group] = this.COPY(this.$store.state.finemailer.defaultNewsletterConfig[group])
                } else {
                    if ( typeof this.EMAIL_CONFIG[group] === "object" && !Array.isArray(this.EMAIL_CONFIG[group]) ) {
                        Object.keys(this.$store.state.finemailer.defaultNewsletterConfig[group]).forEach(param=> {
                            if ( !this.EMAIL_CONFIG[group][param]) {
                                this.$store.state.finemailer.newsletterConfig[group][param] =  this.COPY(this.$store.state.finemailer.defaultNewsletterConfig[group][param])
                            }
                        })
                    }
                }
            })
            this.$store.state.finemailer.newsletterConfig = this.COPY(this.$store.state.finemailer.newsletterConfig)
        },
        EMAIL_COLOR(color) {
            if ( this.EMAIL_COLOR_PALETTE_KEYS.includes(color) ) {
                if ( !this.EMAIL_CONFIG || !this.EMAIL_CONFIG.palette) {
                    return 'orange'
                }
                return this.EMAIL_CONFIG.palette[color]
            }
            return color
        },
        EMAIL_CONFIG_COLOR(param) {

            if ( !this.EMAIL_CONFIG.colors) {
                return ''
            }
            if ( !this.EMAIL_CONFIG.colors[param]) {
                return ''
            }
            return this.EMAIL_COLOR(this.EMAIL_CONFIG.colors[param])
        },
        EMAIL_CONFIG_COLOR_PARAM(param) {
            if ( !this.EMAIL_CONFIG.colors) {
                return ''
            }
            if ( !this.EMAIL_CONFIG.colors[param]) {
                return ''
            }
            return this.EMAIL_CONFIG.colors[param]
        },
    },
    watch : {
        config : {
            handler() {
                this.CONFIG = this.config
            },
            deep : true
        }
    },
    beforeMount() {
        if ( this.config ) {
            this.CONFIG = this.config
        }

    },
}